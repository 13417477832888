import Vue from "vue";

/*TITLE*/
document.title = "Paterna By ÚNICO HOMES | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Paterna By ÚNICO HOMES";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Paterna By ÚNICO HOMES";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_vela-smoked_443x443--20230126030151.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "bp_newport-beige_333x592-old-beige_333x592-newport-beige_443x443--20230126030140.jpg";
Vue.prototype.$image_bath2 = "bsec_urban-black-nature_333x592-cubik-urban-black_333x592-urban-black-nature_443x443--20230126030133.jpg";
Vue.prototype.$image_room = "habitacion_vela-smoked_443x443--20230126030105.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_vela-smoked_443x443--20230126030151.jpg",
  },
  {
    src: "salon_ac4-home-1l-salzburg_193x1383--20230126030159.jpg",
  },
  {
    src: "salon_ac4-home-1l-kaprun_193x1383--20230126030106.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "bp_newport-beige_333x592-old-beige_333x592-newport-beige_443x443--20230126030140.jpg",
  },
  {
    src: "bp_matika-silver_333x592-stripe-matika-silver_333x592-matika-silver_443x443--20230126030102.jpg",
  },
  {
    src: "bp_marmol-carrara-blanco_333x592-carrara-blanco-brillo_446x446--20230126030114.jpg",
  },
  {
    src: "bp_urban-black-nature_333x592-cubik-urban-black_333x592-urban-black-nature_443x443--20230126030138.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "bsec_urban-black-nature_333x592-cubik-urban-black_333x592-urban-black-nature_443x443--20230126030133.jpg",
  },
  {
    src: "bsec_newport-beige_333x592-old-beige_333x592-newport-beige_443x443--20230126030140.jpg",
  },
  {
    src: "bsec_matika-silver_333x592-stripe-matika-silver_333x592-matika-silver_443x443--20230126030148.jpg",
  },
  {
    src: "bsec_marmol-carrara-blanco_333x592-carrara-blanco-brillo_446x446--20230126030156.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "habitacion_vela-smoked_443x443--20230126030105.jpg",
  },
  {
    src: "habitacion_ac4-home-1l-salzburg_193x1383--20230126030111.jpg",
  },
  {
    src: "habitacion_ac4-home-1l-kaprun_193x1383--20230126030119.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20roses%2023%20paterna%20",
    text: "C. Roses, 23 (Paterna)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=avda%20vicente%20mortes%20alfonso%2059%20paterna%20",
    text: "Avda. Vicente Mortes Alfonso 59 (Paterna)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:960105508",
    text: "960105508",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@proelconsultoria.com",
    text: "info@proelconsultoria.com",
  },
];
